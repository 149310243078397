import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "orden-aleatorio",
      "style": {
        "position": "relative"
      }
    }}>{`Orden aleatorio`}<a parentName="h1" {...{
        "href": "#orden-aleatorio",
        "aria-label": "orden aleatorio permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "porqué",
      "style": {
        "position": "relative"
      }
    }}>{`Porqué`}<a parentName="h2" {...{
        "href": "#porqu%C3%A9",
        "aria-label": "porqué permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Vamos a aprender cómo atacar los smart contracts con otros smart contracts, porque esta es la práctica activa de `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`opuestos complementarios`}</a>{`. Al comprender las diferentes vulnerabilidades que se han explotado a lo largo del tiempo, podemos aprender cómo funcionan realmente los "smart" contracts y cómo emplear mejores patrones de pensamiento que mejorarán nuestro oficio.`}</p>
    <p>{`Al tomar conciencia de nuestras limitaciones y debilidades, nos volvemos más fuertes y resilientes. Al mirar en las sombras, tenemos la certeza de que hay luz.`}</p>
    <h2 {...{
      "id": "preparación",
      "style": {
        "position": "relative"
      }
    }}>{`Preparación`}<a parentName="h2" {...{
        "href": "#preparaci%C3%B3n",
        "aria-label": "preparación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Asegúrate de que todavía te queda algo de Sepolia ETH en su cuenta. Utiliza el `}<a parentName="li" {...{
          "href": "https://www.alchemy.com/faucets/ethereum-sepolia"
        }}>{`grifo`}</a>{` si necesitas recargar.`}</li>
      <li parentName="ol">{`Si te sientes muy motivado, familiarízate con los entornos de desarrollo integrados (IDE) y `}{`[Remix]`}{` (`}<a parentName="li" {...{
          "href": "https://remix.ethereum.org/"
        }}>{`https://remix.ethereum.org/`}</a>{`).`}</li>
      <li parentName="ol">{`Siéntate y respira tranquilamente durante al menos 10 minutos.`}</li>
    </ol>
    <h2 {...{
      "id": "aplicación",
      "style": {
        "position": "relative"
      }
    }}>{`Aplicación`}<a parentName="h2" {...{
        "href": "#aplicaci%C3%B3n",
        "aria-label": "aplicación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta lección, afrontaremos los próximos 2 desafíos de Ethernaut: CoinFlip y Teléfono. Esto requiere escribir nuestros propios contratos y aprender a compilarlos y desplegarlos, para lo cual utilizaremos el IDE proporcionado por la Fundación Ethereum, llamado Remix.`}</p>
    <ol>
      <li parentName="ol">{`¿Qué es un IDE y para qué sirve en esta vida?`}</li>
      <li parentName="ol">{`¿Cómo puedo leer y comprender lo que sucede en un contrato inteligente?`}</li>
      <li parentName="ol">{`¿Qué son las variables globales y qué tan global es global?`}</li>
      <li parentName="ol">{`¿De dónde se originan realmente mis transacciones?`}</li>
      <li parentName="ol">{`¿Qué significa redactar un contrato? ¿Qué es una ABI y qué es el código de bytes?`}</li>
      <li parentName="ol">{`Espera, ¿quieres decir que todos estos contratos realmente pueden comunicarse entre sí?`}</li>
    </ol>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta sesión habrás aprendido:`}</p>
    <ol>
      <li parentName="ol">{`Cómo leer Solidity con más detalle, junto con algunos de los matices que tiene.`}</li>
      <li parentName="ol">{`Cómo implementar tus propios contratos directamente desde el navegador.`}</li>
      <li parentName="ol">{`Cómo programar contratos para interactuar con otros contratos.`}</li>
      <li parentName="ol">{`Qué difícil es programar la aleatoriedad y cómo una única superficie informática global compartida hace que esto sea aún más desafiante.`}</li>
      <li parentName="ol">{`Por qué todo el mundo tiene acceso a la misma información al mismo tiempo es algo muy interesante, pero también un contexto muy desafiante para programar.`}</li>
      <li parentName="ol">{`Cómo incluso un código muy simple puede tener consecuencias no deseadas si no eres cuidadoso y considerado acerca de cómo abordas tu oficio.`}</li>
    </ol>
    <h2 {...{
      "id": "grabación",
      "style": {
        "position": "relative"
      }
    }}>{`Grabación`}<a parentName="h2" {...{
        "href": "#grabaci%C3%B3n",
        "aria-label": "grabación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/xoibbA73050" mdxType="Video" />
    <Aligner center mdxType="Aligner">
      <Image alt="Rule 30" src="/images/build/rule_30.png" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      